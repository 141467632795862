.highlightable-marker {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;

  &:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
}

.shortened {
  white-space: nowrap;
  overflow: hidden;
  max-width: 200px;
  text-overflow: ellipsis;
}
