.gallery-image {
  max-width: 100%;
  max-height: calc(var(--vh, 100vh) - 300px);
  margin: 0 auto;
  display: block;

  @media screen and (max-height: 600px) {
    max-height: 300px;
  }

  &.loading {
    -webkit-filter: blur(3px) grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: blur(3px) grayscale(100%);
  }

  &-upload {
    margin: 0 auto 10px;
  }
}

button.carousel-control-prev,
button.carousel-control-next {
  opacity: 0.5 !important;
}

button.carousel-control-prev:hover,
button.carousel-control-next:hover {
  opacity: 1 !important;
}

button.carousel-control-disabled,
button.carousel-control-disabled:hover {
  cursor: not-allowed;
  opacity: 0.2 !important;
}

// .carousel-control-short {
//   margin: 100px 0;
// }

.stars {
  display: inline-block;
  margin: -2px;

  span {
    line-height: 16px;
  }
}

.fullscreen .carousel {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #000;

  display: flex;
  align-items: center;

  & > * {
    flex-grow: 1;
  }

  .gallery-image {
    max-height: 100vh;
  }
}

.fullscreen .footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  padding: 5px 10px;
  background-color: rgba($color: #000000, $alpha: 0.5);
}

.react-tags__suggestions {
  z-index: 10;
}
