.dropdown-menu {
  z-index: 10000;
}

.dropdown-long {
  overflow-x: auto;
  max-height: calc(var(--vh, 100vh) - 150px);
}

.accordion > .card > .card-header {
  cursor: pointer;
}

// dynamic modal width
@media (min-width: 768px) {
  .modal.dynamic {
    display: flex !important;

    .modal-dialog {
      min-width: 600px;
      max-width: 100%;
      width: auto !important;
      display: inline-block;
    }
  }
}

.carousel-control-prev,
.carousel-control-next {
  border: 0;
  background-color: rgba(0, 0, 0, 0);
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: hsla(0, 0%, 0%, 0.2);
  border-radius: 50%;
  background-origin: content-box;
  padding: 5px;
  width: 30px;
  height: 30px;
}

.form-group.required .form-label:after {
  content: ' *';
  color: red;
}

@media (max-width: 767px) {
  kbd {
    display: none;
  }
}

// let active looks like dark variant
.btn-secondary:not(:disabled):not(.disabled).active {
  background-color: #343a40;
  border-color: #343a40;

  &:hover,
  &:focus {
    background-color: #23272b;
    border-color: #1d2124;
  }
  // color: #5a6268;
  // background-color: #fff;
  // border-color: #5a6268;
}

kbd {
  background-color: #bbb;
}

.f-gap-1 {
  gap: 0.25rem;
}

.f-gap-2 {
  gap: 0.5rem;
}

.dropdown-item {
  @media (pointer: coarse) {
    padding: 0.5rem 1.5rem;
  }
}

.fm-map-layers-dropdown .dropdown-menu {
  max-height: 50vh;
  overflow-y: auto;
}

legend {
  font-size: 1rem;
  font-weight: bold;
}

.text-pre {
  white-space: pre;
}

.btn-group {
  // Reset rounded corners
  > .btn:not(:last-child).fm-dropdown-toggle-nocaret {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
