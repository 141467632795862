@use '~bootstrap/scss/bootstrap';

@use './bootstrap-override.scss';

@use './leaflet.scss';

:root {
  --scroller-mix-blend-mode: multiply;
}

body {
  overflow: hidden;
}

.hidden {
  display: none !important;
}

.header {
  position: absolute;
  z-index: 1020;
  top: 0px;
  left: 0px;
  right: 0px;
  pointer-events: none;

  & > * {
    pointer-events: auto;
  }
}

.info-bar {
  @media screen {
    background-color: #ffa;
    text-align: center;

    & > button {
      float: right;
      margin: 0 4px;
    }
  }
  @media print {
    display: none;
  }
}

.menus {
  pointer-events: none;
  & > * {
    pointer-events: auto;
  }
  display: flex;
  flex-wrap: wrap;
}

.tool-panel {
  position: absolute;
  z-index: 1010;
  top: 10px;
  left: 68px;
  margin-right: 10px;
}

#freemap-logo-print {
  @media print {
    top: 4px;
    left: 4px;
    z-index: 2000;
    position: absolute;
    display: block !important;
  }

  @media screen {
    display: none;
  }
}

button#freemap-logo {
  border: none;
  outline: none;
  background-color: rgba(255, 255, 255, 0);
  background-image: url(../images/freemap-logo.png);
  background-size: 122px 34px;
  background-repeat: no-repeat;
  margin-right: 3px;
  height: 34px;
  width: 122px;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;

  &.in-progress {
    animation: pulse 1s infinite;
  }

  &:hover {
    cursor: pointer;
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
}

@media (max-width: 767px) {
  .full {
    button#freemap-logo {
      background-image: url(../images/freemap-logo-small.png);
      background-size: 42px 34px;
      width: 42px;
    }
  }
}

@keyframes pulse {
  0% {
    filter: brightness(100%);
  }
  50% {
    filter: brightness(200%);
  }
  100% {
    filter: brightness(100%);
  }
}

.fm-bg {
  background-color: rgba(255, 255, 255, 0.8);
}

.fm-toolbar {
  @media screen {
    min-height: 3rem;
    background-color: rgba(255, 255, 255, 0.8);
    display: inline-flex;
    // flex-wrap: wrap;
    flex-direction: row;
    vertical-align: top;
    align-items: center;

    padding: 0.25rem;

    form {
      display: inline;
    }
  }

  @media print {
    display: none !important;
  }

  & > .btn-toolbar {
    flex-wrap: nowrap;

    & * {
      white-space: nowrap;
    }
  }
}

.fm-ib-scroller {
  overflow: auto;
}

.fm-label {
  margin: 4px;
}

#tools-button {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.fm-type-zoom-control {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  z-index: 1010;
  bottom: 0;
  left: 0;
  right: 0;

  & > div:first-child {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    max-width: 100vw;

    & > div {
      pointer-events: auto;
    }
  }

  & > div:last-child {
    align-self: flex-end;
    pointer-events: auto;
  }
}

.legend-item {
  display: flex;
  margin-bottom: 2px;
  align-items: center;

  & > *:nth-child(1) > div {
    min-width: 20px;
  }

  & > *:nth-child(2) {
    flex-grow: 1;
    padding-left: 10px;
  }
}

.fm-no-after::after {
  display: none !important;
}

.input-group > input.form-control {
  min-width: 100px;
}

.picker-dialog-bg {
  z-index: 10000 !important;
}

.picker-dialog {
  z-index: 10001 !important;
}

.fm-menu-scroller {
  // hack to prevent popovers go outside view on mobiles
  max-height: calc(var(--vh, 100vh) - 120px);
  overflow-y: auto;
}

.fm-ib-scroller::before,
.fm-ib-scroller > div:first-child::before,
.fm-menu-scroller::before,
.fm-menu-scroller > div:first-child::before,
.dropdown-long::before,
.dropdown-long > div:first-child::before {
  content: '';
  position: fixed;
  z-index: 100;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.25s;
}

.fm-ib-scroller::before {
  width: 2rem;
  height: 3rem;
  mix-blend-mode: var(--scroller-mix-blend-mode);
  background: linear-gradient(
    to right,
    rgba(224, 224, 224, 1) 5%,
    rgba(224, 224, 224, 0)
  );
}

.fm-ib-scroller > div:first-child::before {
  width: 2rem;
  height: 3rem;
  right: 0;
  mix-blend-mode: var(--scroller-mix-blend-mode);
  background: linear-gradient(
    to right,
    rgba(224, 224, 224, 0) 5%,
    rgb(224, 224, 224)
  );
}

.fm-ib-scroller-top::before {
  width: 2rem;
  height: 3rem;
  mix-blend-mode: var(--scroller-mix-blend-mode);
  background: linear-gradient(
    to right,
    rgb(224, 224, 224) 5%,
    rgba(224, 224, 224, 0)
  );
}

.fm-ib-scroller-top::before,
.fm-ib-scroller-top > div:first-child::before {
  margin-top: 0.5rem;
}

.fm-menu-scroller::before,
.dropdown-long::before {
  width: 100%;
  height: 2rem;
  mix-blend-mode: var(--scroller-mix-blend-mode);
  background: linear-gradient(
    to bottom,
    rgb(224, 224, 224) 5%,
    rgba(224, 224, 224, 0)
  );
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.fm-menu-scroller > div:first-child::before,
.dropdown-long > div:first-child::before {
  width: 100%;
  height: 2rem;
  bottom: 0;
  mix-blend-mode: var(--scroller-mix-blend-mode);
  background: linear-gradient(
    to bottom,
    rgba(224, 224, 224, 0) 5%,
    rgb(224, 224, 224)
  );
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.fm-menu-scroller::before,
.fm-menu-scroller > div:first-child::before {
  // margin-left: -0.75rem;
  margin-top: -0.5rem;
}

.dropdown-long::before,
.dropdown-long > div:first-child::before {
  margin-top: -0.5rem;
  // margin-bottom: -0.5rem;
}

.scroll-left::before,
.scroll-right > div:first-child::before,
.scroll-top::before,
.scroll-bottom > div:first-child::before {
  opacity: 1;
  transition: opacity 0.25s;
}

.markdown {
  & table {
    margin-bottom: 1rem;
  }

  & th,
  & td {
    border: 1px solid black;
    padding: 0 0.5em;
  }
}

.pe-none {
  pointer-events: none;
}

.fm-drag-to-map {
  background-color: rgba(217, 237, 247, 50%);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20000;
  pointer-events: none;
}

.dropzone {
  margin: 10px;
  border: 2px dashed grey;
  padding: 30px;
  border-radius: 5px;
  cursor: default;

  &:hover,
  &-dropping {
    background-color: #d9edf7;
  }
}

.watermark {
  position: absolute;
  left: 5px;
  bottom: 28px;
  z-index: 999;
}

.fm-fs-content {
  field-sizing: content;
}
